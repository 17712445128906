.news {
  width: 20rem;
  border-radius: var(--borderRadius);
  box-shadow: 0 0 2rem -1rem rgb(var(--lightBlue));
  padding: 0.1rem;
  position: relative;
  padding: 0.5rem;
}

.tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: var(--borderRadius);
  background: rgb(var(--lightBlue));
  color: white;
  padding: 0.5rem;
}

.tag p {
  font-size: 1rem;
  height: 1.5rem;
}

.image {
  width: 100%;
  object-fit: cover;
  border: none;
  height: 20rem;
  cursor: pointer;
}

.text-part {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  justify-content: flex-start;
}

.text {
  text-align: left;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.modalText {
  font-size: 1rem;
  text-align: left;
  height: 8.5rem;
  overflow-y: scroll;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

:root{
  /* Colors */
  --mainBlue: 105, 185, 255;
  --lightBlue: 62, 136, 239;
  --darkBlue: 11, 29, 87;
  --lightGray: 128, 128, 128;
  --mainBackground: 225, 241, 255;
  
  /* Size */
  --minWidth: 1260px;
  --borderRadius: .5rem;
  
  /* Fonts styles */
  --textBaseFontSize: 1.6rem
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 5rem;
  height: 6rem;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 2rem -1rem rgb(var(--lightBlue));
}

.left-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-part > .logo {
  width: 6rem;
}
.left-part > .title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 20rem;
}
.left-part > .title-container > .title {
  width: 100%;
  color: #0375c2;
  font-size: 1.2rem;
  text-align: center;
}

.left-part > .title-container > .sub-title {
  font-size: 0.6rem;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.midle-part button {
  color: black;
  font-weight: 700;
  font-size: 1rem;
}

/* .midle-part button[aria-selected='true'] {
  border-radius: 10rem;
  background: aliceblue;
} */

.right-part button {
  background: #69b9ff;
  border-radius: 10rem;
  height: 3rem;
}

.right-part button {
  color: aliceblue;
}

.right-part button:hover {
  background-color: rgba(25, 118, 210, 0.64);
}

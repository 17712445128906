.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
}

.infoRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 8rem;
}

.linksRow::before {
  content: '';
  height: 1px;
  background: black;
  display: block;
}

.linksRow {
  width: 100%;
  margin-top: 3rem;
}

.contacts {
}

.title {
  color: rgb(var(--lightBlue));
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.item {
  margin: 1rem 0;
}

.addres {
}

.logo {
  width: 8rem;
}

.name {
  font-weight: 600;
  margin-top: 2rem;
}

.links {
}

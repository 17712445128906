@import 'assets/styles/style.var.css';
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  cursor: context-menu;
  user-select: none;
}

.title {
  font-size: 2rem;
  color: rgb(var(--darkBlue));
  margin: 0;
  text-transform: uppercase;
}

.title.light-blue {
  color: rgb(var(--lightBlue));
}

.title.sm {
  font-size: 1.4rem;
}

.container {
  padding: 3rem 10vw 5vw;
  width: 100%;
}

.block {
  padding: 1.5rem 0;
}

.text {
  font-size: 1.2rem;
}

button {
  white-space: nowrap;
}

.tooltip p {
  cursor: inherit;
}

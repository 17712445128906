.simpleList {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: 1.5rem;
}

.pointer {
  list-style: disc;
}
.numeric {
  list-style: decimal;
}

.item {
  margin: 0.5rem 0;
}

.formContainer {
    width: 70rem;
    margin: auto;
    display: flex;
    align-items: start;
    gap: 5rem;
}

.toggleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3rem;
}

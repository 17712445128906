.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  gap: 2rem;
}

.avatar {
  width: 16rem;
  height: auto;
}

.textContainer {
  height: 100%;
}

.level {
  color: rgb(var(--lightBlue));
  text-transform: uppercase;
}

.description {
  margin-top: 2rem;
  -webkit-line-clamp: 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

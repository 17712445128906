.container {
  padding: 3rem 10vw 0rem;
}

.contacts {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.contactsData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.line {
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(var(--lightGray), 0.3);
}

.contactItem {
}

.block {
  margin: 1.5rem 0;
}

.title {
  text-transform: uppercase;
  font-weight: 700;
}

.data {
  text-transform: uppercase;
  font-weight: 500;
  cursor: copy;
  user-select: all;
}

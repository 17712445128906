.container {
  position: relative;
}

.page {
  width: 50rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 55%;
  margin: auto;
  margin-bottom: 1rem;
}

.controls p {
  font-size: 1.2rem;
  text-align: nowrap;
}

.pageContainer {
  opacity: 1;
  transition: opacity .25s;
}

.isLoading {
  opacity: 0;
}

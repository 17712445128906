.tooltip {
  transition: all 1s cubic-bezier(.19,1,.22,1);
  cursor: pointer;
}

.tooltip:hover {
  transform: scale(1.05);
  z-index: 2;
  box-shadow: 0 17px 43px #b6b6b6;
}

.button {
  text-align: nowrap;
}

.oval {
  background: rgba(var(--mainBlue));
  border-radius: 10rem;
  height: 3rem;
  color: white;
}

.transparent {

}

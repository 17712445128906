.aboutContainer,
.aboutTextsAndImage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.aboutTextsAndImage {
  flex-direction: row;
  align-items: flex-start;
  gap: 3rem;
}

.textItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  flex: 1;
}

.textItems p {
  font-size: var(--textBaseFontSize);
}

.aboutTextsAndImage img {
  flex: 0.5;
}

.worker {
  object-fit: cover;
  width: 25%;
  height: 40rem;
}

/* Images */
.imageContainer {
  background-color: white;
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}

.imgAbout {
  object-fit: contain;
  width: 35%;
}

/* Employes */
.employes {
  padding-top: 0;
}
.employers {
  padding-bottom: 1.5rem;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

/* Partners and customers */
.PCContainer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;

}

.PC {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.PCTitleText {
  width: 50%;
  text-align: center;
}

.partnersImages {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.partnerImage {
  width: 8rem;
  height: auto;
  object-fit: contain;
  border-radius: 15rem;
  overflow: hidden;
}

.customerList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

/* Modal */

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.description {
  text-align: left;
  overflow: hidden;
  overflow-y: auto;
  max-height: 30rem;
  line-height: 1.6rem;
  white-space: pre-line;
}

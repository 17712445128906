.main-info {
  width: 100%;
  box-sizing: border-box;
}
/**************Order List*****************/
.order-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 5vw;
}

.order-list .items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8vw;
  width: 100%;
}

.order-list .item {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  align-items: flex-start;
}

.order-list .item > .item-text {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  background: #ffffffa8;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
}

.order-list .item > .item-text > .item-description {
  text-align: left;
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.order-list .item > .item-text > a {
  font-size: 2rem;
  color: #464646;
}

.order-list .item > .item-btn button {
  background: rgba(var(--mainBlue));
  border-radius: 10rem;
  height: 3rem;
  color: white;
}

.order-list .item > .item-btn button:hover {
  background-color: rgba(25, 118, 210, 0.64);
}

.order-item__title {
  font-size: 2rem;
}

/**************Expertise List*****************/

/* 
.expertise-list {
  background: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 8rem 0;
  margin-top: 5rem;
}

.expertise-list > .box {
  display: flex;
  width: 80vw;
  margin: auto;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    0deg,
    rgba(42, 179, 187, 0.28) 0%,
    rgba(255, 2, 171, 0.1) 100%
  );
  padding: 1rem;
  border-radius: var(--borderRadius);
} */

/**************Guarantees*****************/
.guarantees {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: white;
}

.guarantees .items {
  display: flex;
  gap: 10rem;
  justify-content: center;
  align-items: flex-start;
}

.guarantees .item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.guarantees .item .text {
  text-align: center;
  font-size: 1.4rem;
  color: black;
  width: auto;
}

.guarantees .item .title {
  font-size: 5.8rem;
  color: rgba(var(--lightBlue));
}

.guarantees .item hr {
  width: 15%;
  color: rgba(var(--lightBlue));
}

.guarantees .item i {
  font-size: 4rem;
}

/**************AboutUs*****************/

.about > .items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}

.about .items .item .title.sm {
  font-size: 1.8rem;
  color: rgba(var(--lightBlue));
  font-weight: 600;
  margin-bottom: 2rem;
  display: block;
}

.about .items .item p {
  width: 35rem;
  font-size: 1.4rem;
  margin-top: 2rem;
}

/**************News*****************/

.news {
  padding: 3rem 10vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.news .items {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 3rem;
}

.news button {
  width: 12rem;
  height: 3rem;
  margin-top: 3rem;
}
